import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCdCeFWEc9k54zUMa7l9sULqkJ1_J28Sak',
  authDomain: 'lechuzas-celestes.firebaseapp.com',
  projectId: 'lechuzas-celestes',
  storageBucket: 'lechuzas-celestes.appspot.com',
  messagingSenderId: '364759295150',
  appId: '1:364759295150:web:2f3f084e561cbe317fb93f',
  measurementId: 'G-7YE3Z65K5H'
}

export const FirebaseApp = initializeApp(firebaseConfig)
export const FirebaseAuth = getAuth(FirebaseApp)
export const FirebaseDB = getFirestore(FirebaseApp)
