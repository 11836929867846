import React from 'react'
import { Box, Flex, Image, useColorModeValue } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const Navbar: React.FC = () => {
  const navigate = useNavigate()

  const goHome = (): void => {
    navigate('/')
  }
  return (
    <Box position={'fixed'} width={'100vw'} zIndex={9}>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex flex={{ base: 1 }} justify={{ base: 'center' }}>
          <Image
            src='/img/logo-as-lechuzas.jpg'
            width={300}
            alt='Logo'
            onClick={goHome}
            borderRadius={'md'}
            cursor={'pointer'}
            />
        </Flex>
      </Flex>
    </Box>
  )
}

export default Navbar
