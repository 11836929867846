export const mailerConfig = {
  host: 'mailtrack.rodrigovaamonde.com',
  folder: 'api',
  secure: true,
  auth: {
    username: 'info@lechuzascelestes.com',
    password: '42NF8Q8by&3yL%UChe'
  },
  adminEmail: 'lechuzascelestes@gmail.com'
  // adminEmail: 'vaamonde.rodri@gmail.com'
}
