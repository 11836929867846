import { type SendEmailData } from '../models/SendEmailData'
import { type HookieFormData } from '../models/HookieFormData'

export const hookieFormRsvpDataToSendEmailDataMapper = (data: HookieFormData): SendEmailData => {
  return {
    to: data.email,
    template: '',
    vars: {
      name: data.name,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      size: data.size
    }
  }
}
