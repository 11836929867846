import React, { type JSX } from 'react'
import { chakra, useColorModeValue, VisuallyHidden } from '@chakra-ui/react'

interface Props {
  children: JSX.Element
  label: string
  href: string
}

const SocialButton = ({ children, label, href }: Props): JSX.Element => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200')
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

export default SocialButton
