import React, { type JSX } from 'react'
import './App.css'
import 'animate.css'
import AppRouter from './router/AppRouter'

function App (): JSX.Element {
  return (
    <div className="App">
      <AppRouter/>
    </div>
  )
}

export default App
