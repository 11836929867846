import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import config from '../../config/config'
import ChristmasDinnerForm from '../../components/christmas-dinner-form/ChristmasDinnerForm'

const ChristmasDinnerFormPage: React.FC = () => {
  return (
    <ChakraProvider>
      <Navbar/>

      {
        config.CHRISTMAS_DINNER_AVAILABLE ? <ChristmasDinnerForm/> : <></>
      }

      <Footer/>
    </ChakraProvider>
  )
}

export default ChristmasDinnerFormPage
