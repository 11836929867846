import React, { type JSX } from 'react'
import { Box, Container, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import SocialButton from './SocialButton'

const Footer = (): JSX.Element => {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <img
          src='/img/logo.png'
          width={150}
          height={50}
          alt='Logo'
        />
        <Text>© 2024 Le-Chuzas Celestes. Tódolos dereitos reservados.</Text>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Twitter'} href={'https://twitter.com/le_chuzas'}>
            <FaTwitter/>
          </SocialButton>
          <SocialButton label={'Facebook'} href={'https://www.facebook.com/lechuzas.celestes.1'}>
            <FaFacebook/>
          </SocialButton>
          <SocialButton label={'Instagram'} href={'https://www.instagram.com/lechuzascelestes/'}>
            <FaInstagram/>
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  )
}

export default Footer
