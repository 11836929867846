export enum MailTemplates {
  REGISTER_ADMIN_NOTIFICATION = 'register_admin_notification',
  REGISTER_COMPLETED = 'register_completed',
  ANNIVERSARY_SIGNUP_COMPLETED = 'anniversary_signup_completed',
  ANNIVERSARY_SIGNUP_ADMIN_NOTIFICATION = 'anniversary_signup_admin_notification',
  HOOKIE_REGISTER = 'hookie_register',
  HOOKIE_REGISTER_ADMIN_NOTIFICATION = 'hookie_admin_notification',
  CHRISTMAS_DINNER_SIGNUP_COMPLETED = 'christmas_dinner_signup_completed',
  CHRISTMAS_DINNER_SIGNUP_ADMIN_NOTIFICATION = 'christmas_dinner_signup_admin_notification'
}
