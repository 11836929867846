import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import { ChakraProvider } from '@chakra-ui/react'
import config from '../../config/config'
import HookieForm from '../../components/hookie-form/HookieForm'

const HookieFormPage: React.FC = () => {
  return (
    <ChakraProvider>
      <Navbar />

      {
        config.HOOKIE_AVAILABLE ? <HookieForm /> : <></>
      }

      <Footer />
    </ChakraProvider>
  )
}

export default HookieFormPage
